<template>
  <v-sheet color="background-linear" rounded="lg" class="pa-4">
    <v-img
      class="d-flex d-sm-none"
      :src="require('@/assets/icons/wallet-connect-footer-ic.svg')"
      :max-width="196"
      style="margin: 0 auto"
    />
    <div class="d-flex flex-column flex-sm-row justify-sm-space-between align-sm-center">
      <div class="flex flex-column flex-grow-0 black--text">
        <div class="message-1">Login or Sign Up with your wallet to access the opt-in page</div>
        <!-- <div class="message-2">Connect wallet and log in to start using Thinkin</div> -->
      </div>
      <v-img
        class="d-none d-sm-flex d-lg-none"
        :src="require('@/assets/icons/wallet-connect-footer-ic.svg')"
        :max-width="196"
        style="margin: 0 auto"
      />
      <v-img
        class="d-none d-lg-flex"
        :src="require('@/assets/icons/wallet-connect-footer-big-ic.svg')"
        :max-width="380"
        style="margin: 0 auto"
      />
      <v-btn class="px-12 px-lg-16 mx-auto mx-sm-0" to="/login">Log in</v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { walletStore } from '@/stores/wallet-store'
// 
export default {
  methods: {
    connect () {
      if (!walletStore.walletConnected) {
        walletStore.setShowRequireLoginDialog(true)
      } else if (!walletStore.isRegisted) {
        this.$router.push('/register')
      } else if (!walletStore.isLogin) {
        walletStore.setShowRequireLoginDialog(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/breakpoint-custom.scss';

.message-1 {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  padding-top: 10px;

  @include breakpoint(tablet) {
    text-align: start;
    padding-top: 0px;
  }

  @include breakpoint(desktop) {
    font-size: 20px;
  }
}
.message-2 {
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;

  @include breakpoint(tablet) {
    text-align: start;
    padding-bottom: 0px;
  }

  @include breakpoint(desktop) {
    font-size: 13px;
  }
}

</style>