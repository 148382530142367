



















































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { TrendingNoAuthViewModel } from '../viewmodels/trending-no-auth-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import WalletConnectFooter from '../../../../components/auth/wallet-connect-footer.vue'
import WalletConnectForceDialog from '../../../../components/auth/wallet-connect-required-dialog.vue'
import { debounce } from 'lodash'

@Observer
@Component({
  components: {
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
    'post-list': () => import('@/modules/community/common/components/post-list.vue'),
    'group-button': () => import('@/modules/community/common/components/group-button.vue'),
    'community-owner-section': () => import('@/modules/common/components/community-owner-section.vue'),
    'discovery-area': () => import('@/modules/community/home/components/discovery-area.vue'),
    observer: () => import('vue-intersection-observer'),
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
    WalletConnectFooter,
    WalletConnectForceDialog,
  },
})
export default class ConnectWalletStatus extends Vue {
  @Provide() vm = new TrendingNoAuthViewModel()
  walletStore = walletStore
  createPostController = createPostController

  loadMoreCount = 0
  walletFooterWidth = 0

  folowTag(tag: string) {
    //
  }

  loadMore(entry, unobserve) {
    if (this.loadMoreCount++ && entry.isIntersecting) {
      walletStore.setShowRequireLoginDialog(true)
      // if (this.vm.postsStore?.canLoadMorePost) {
      //   this.vm.postsStore.loadMore()
      // }
    }
  }
  destroyed() {
    this.vm.destroy()
  }

  mounted() {
    this.getWalletFooterWidth()
    window.addEventListener('resize', debounce(this.getWalletFooterWidth, 200))
  }

  unmounted() {
    window.removeEventListener('resize', debounce(this.getWalletFooterWidth, 200))
  }

  getWalletFooterWidth() {
    const walletFooter = document.getElementById('wallet-footer')
    const width = walletFooter?.parentElement?.offsetWidth || 0
    this.walletFooterWidth = width
  }
}
