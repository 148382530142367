







































import { walletStore } from '@/stores/wallet-store'
import { IReactionDisposer, reaction } from 'mobx'
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class WalletConnectRequiredDialog extends Vue {
  valueSync = false

  wallet = walletStore

  _diposers: IReactionDisposer[] = []

  created() {
    this._diposers = [
      reaction(() => walletStore.showRequireLoginDialog, (val) => {
        this.valueSync = val
      })
    ]
  }

  destroyed() {
    this._diposers.forEach(d => d())
  }
  
  login() {
    this.wallet.signIn()
  }

  close() {
    this.wallet.setShowRequireLoginDialog(false)
  }

  get form() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
      case 'md':
        return { height: 320, square: 134, icon: 47, maxIcWidth: 220 }
      case 'lg':
      case 'xl':
        return { height: 570, square: 226, icon: 80, maxIcWidth: 300 }
    }
  }
}
