import { action, computed, observable } from 'mobx'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { TagModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { asyncAction } from 'mobx-utils'
import { PostsStore } from '@/stores/posts-store'
import moment from 'moment'
import { appProvider } from '@/app-provider'
import { walletStore } from '@/stores/wallet-store'

export class TrendingNoAuthViewModel {
  @observable postsStore?: PostsStore
  @observable hotTags: TagModel[] = []
  @observable tagFilter = false
  @observable selectedTag?: TagModel
  @observable hotTagUpdating = false
  @observable totalHotTags = 0
  @observable hotTagPage = 0

  constructor() {
    this.fetchData()
  }

  @action fetchData() {
    this.postsStore = new PostsStore({ _sort: 'createdAt:desc' })
    this.fetchHotTags()
  }

  destroy() {
    this.postsStore?.destroy()
  }

  //fetch post
  @asyncAction *fetchSelectedTag() {
    const tagId = appProvider.router.currentRoute.query.tags
    const tags = yield apiService.tags.find({ id: tagId })
    this.selectedTag = tags[0]
  }

  @asyncAction *fetchHotTags() {
    try {
      this.hotTagUpdating = true
      const res = yield apiService.posts.getHotTags()
      if (res) {
        this.hotTags = res.data
        this.totalHotTags = res.total
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.hotTagUpdating = false
    }
  }

  @asyncAction *updateHotTags() {
    try {
      this.hotTagUpdating = true
      if (this.hotTagPage + 6 <= this.totalHotTags) this.hotTagPage += 1
      else this.hotTagPage = 0
      const res = yield apiService.posts.getHotTags({ page: this.hotTagPage + 1 })
      if (res) this.hotTags = res.data
    } catch (error) {
    } finally {
      this.hotTagUpdating = false
    }
  }

  //post action
  @action setTagFilter(value: boolean) {
    this.tagFilter = value
  }

  @asyncAction *followTag(tag: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      tag.loading = true
      yield apiService.tagFollows.followTag({ tag: tag })
      tag.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      tag.loading = false
    }
  }

  @asyncAction *unFollowTag(tag: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      tag.loading = true
      yield apiService.tagFollows.unFollowTag({ tag: tag })
      tag.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      tag.loading = false
    }
  }

  get userProfileId() {
    if (walletStore.userInfo) return walletStore.userInfo.profile?._id
    else return ''
  }

  @computed get postStores() {
    return this.postsStore?.posts
  }
}
